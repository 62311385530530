<template>
  <div class="Campaigns">
    <h1 class="text-h5 grey--text text-uppercase">
      Lista de Campanha
    </h1>
    <v-fab-transition v-if="isMobile">
      <v-btn
        color="pink"
        dark
        fixed
        bottom
        right
        fab
        @click="$router.push({ name: 'AddCampaign' })"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-row class="mt-2">
      <v-col v-for="(campaign, index) in campaigns" :key="index">
        <v-card
          :class="{ 'mx-auto': isMobile }"
          :max-width="isMobile ? 344 : 300"
        >
          <v-img
            :src="campaign.pageData.bannerUrl"
            height="200px"
            style="cursor: pointer"
            @click="
              currentUser.role == 'admin'
                ? editCampaign(campaign.id)
                : showCampaign(campaign.id)
            "
          ></v-img>

          <v-card-title>
            {{ campaign.name }}
          </v-card-title>

          <v-card-subtitle class="text-uppercase">
            {{ campaign.client }}
          </v-card-subtitle>

          <v-card-text>
            <strong>Produto</strong>:
            <span class="text-uppercase">{{ campaign.product }}</span> <br />
            <strong>Peça</strong>:
            <span class="text-uppercase"
              >{{ campaign.type }} {{ campaign.format }}</span
            >
            <br />
            <strong>Veiculação</strong>:
            <span class="text-uppercase">{{ campaign.timeFrame }}</span>
          </v-card-text>

          <v-card-actions class="pa-4">
            <v-btn
              v-if="currentUser.role == 'admin'"
              small
              color="primary"
              dark
              @click="editCampaign(campaign.id)"
            >
              <v-icon left>
                mdi-pencil
              </v-icon>
              Editar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              small
              color="primary"
              text
              @click="showCampaign(campaign.id)"
            >
              <v-icon left>
                mdi-open-in-new
              </v-icon>
              Visitar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Campaigns",

  components: {
    //
  },

  mounted() {
    if (this.currentUser && !this.campaigns) {
      const id =
        this.currentUser.role == "admin"
          ? this.currentUser.id
          : this.currentUser.employerId;
      this.$store.dispatch("getAllCampaignsFromUser", id);
    }
  },

  data() {
    return {
      //
    };
  },

  computed: {
    currentUser() {
      return this.$store.getters.getUser;
    },
    campaigns() {
      return this.$store.getters.getCampaigns;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  watch: {
    currentUser(user) {
      const id = user.role == "admin" ? user.id : user.employerId;
      if (user) this.$store.dispatch("getAllCampaignsFromUser", id);
    },
  },

  methods: {
    editCampaign(campaignId) {
      // console.log(campaignId);
      this.$router.push({ name: "editCampaign", params: { id: campaignId } });
    },
    showCampaign(campaignId) {
      this.$router.push({ name: "Campaign", params: { id: campaignId } });
      // let routeData = this.$router.resolve({ name: "Campaign", params: { id: campaignId } });
      // window.open(routeData.href, "_blank");
    },
  },
};
</script>

<style scoped>
.Campaigns {
  padding: 20px;
}
</style>
